import { AWARD_CODES } from "../../common/enums/award-codes";
import { DROPDOWN_LABELS, DROPDOWN_KEYS, ROUTE_PATHS, ROUTE_LABELS } from "../../common/enums/routing-enums";
import { NavData, NavDropdown } from "../../common/interfaces/routing";
import PerformancePlusElite from "../../components/PerfPlusElite/PerfPlusElite";
import MonthlyPGG from "../../components/PGG/MonthlyPGG/MonthlyPGG";
import AnnualPGG from "../../components/PGG/AnnualPGG/AnnualPGG";
import FGG from "../../components/FrontlineGroupGrowth/FrontlineGroupGrowth";
import TTCI from "../../components/TwoTimeCashIncentives/TTCI";
import HAT from "../../components/HAT/HAT";
import FAA from "../../components/FAA/FAA";
import GcrPGG from "../../components/PGG/GCRPGG/GCRPGG";
import { LeadershipSeminar } from "../../components/LeadershipSeminar/LeadershipSeminar";
import { BootUser } from "../../reducers/boot";
import { Config } from "../../common/interfaces/config";
import { ANA_MARKET_CODES } from "../../common/constants/market-constants";
import { QCGrowth } from "../../components/QCGrowth/QCGrowth";
import { filterDropdown } from "./createMenuConfig";

export const LEADER_GROWTH_COMPONENT_MAP = {
  [ROUTE_PATHS.PPE]: <PerformancePlusElite />,
  [ROUTE_PATHS.MONTHLY_PGG]: <MonthlyPGG />,
  [ROUTE_PATHS.ANNUAL_PGG]: <AnnualPGG />,
  [ROUTE_PATHS.PGG_GCR]: <GcrPGG />,
  [ROUTE_PATHS.FGG]: <FGG />,
  [ROUTE_PATHS.TWO_TIME_CASH_INCENTIVE]: <TTCI />,
  [ROUTE_PATHS.HAT]: <HAT />,
  [ROUTE_PATHS.FAA]: <FAA />,
  [ROUTE_PATHS.LEADERSHIP_SEMINAR]: <LeadershipSeminar />,
  [ROUTE_PATHS.QC_GROWTH]: <QCGrowth />,
};

export const createLeaderGrowthDropdown = (
  navConfigs: NavData[],
  { user, configuration }: { user: BootUser; configuration: Config },
): NavDropdown => {
  const { performanceLabel, performanceCode } = getPerformanceIncentive(navConfigs);
  const leaderGrowthDropdown = {
    label: DROPDOWN_LABELS.LEADER_GROWTH,
    key: DROPDOWN_KEYS.LEADER_GROWTH,
    items: [
      {
        label: performanceLabel,
        path: ROUTE_PATHS.PPE,
        class: ROUTE_PATHS.PPE,
        code: performanceCode,
        navPosition: 0,
      },
      {
        label: ROUTE_LABELS.MONTHLY_PGG,
        path: ROUTE_PATHS.MONTHLY_PGG,
        class: ROUTE_PATHS.MONTHLY_PGG,
        code: AWARD_CODES.MONTHLY_PGG,
        navPosition: 1,
      },
      {
        label: getAnnualGroupGrowthLabel(navConfigs),
        path: ROUTE_PATHS.ANNUAL_PGG,
        class: ROUTE_PATHS.ANNUAL_PGG,
        code: AWARD_CODES.ANNUAL_PGG,
        navPosition: 2,
      },
      {
        label: ROUTE_LABELS.PERSONAL_GROUP_GROWTH,
        path: ROUTE_PATHS.PGG_GCR,
        class: ROUTE_PATHS.PGG_GCR,
        code: AWARD_CODES.PGG_GCR,
        navPosition: 3,
      },
      {
        label: ROUTE_LABELS.FGG,
        path: ROUTE_PATHS.FGG,
        class: ROUTE_PATHS.FGG,
        code: AWARD_CODES.FGG,
        navPosition: 4,
      },
      {
        label: ROUTE_LABELS.TTCI,
        path: ROUTE_PATHS.TWO_TIME_CASH_INCENTIVE,
        class: ROUTE_PATHS.TWO_TIME_CASH_INCENTIVE,
        code: AWARD_CODES.TWO_TIME_CASH_INCENTIVE,
        navPosition: 5,
      },
      {
        label: ROUTE_LABELS.HAT,
        path: ROUTE_PATHS.HAT,
        class: ROUTE_PATHS.HAT,
        code: AWARD_CODES.HAT,
        navPosition: 6,
      },
      {
        label: ROUTE_LABELS.FAA,
        path: ROUTE_PATHS.FAA,
        class: ROUTE_PATHS.FAA,
        code: AWARD_CODES.FAA,
        navPosition: 7,
      },
      {
        label: ROUTE_LABELS.LEADERSHIP_SEMINAR,
        path: ROUTE_PATHS.LEADERSHIP_SEMINAR,
        class: ROUTE_PATHS.LEADERSHIP_SEMINAR,
        code: AWARD_CODES.LSI,
        navPosition: 8,
      },
      {
        label: ROUTE_LABELS.QC_GROWTH,
        path: ROUTE_PATHS.QC_GROWTH,
        class: ROUTE_PATHS.QC_GROWTH,
        code: AWARD_CODES.QCG,
        navPosition: 9,
      },
    ],
  };

  const isAna = ANA_MARKET_CODES.includes(user.aff);
  const showLsi = configuration.lsi.showLsi;

  const bothTrue = isAna && showLsi;

  return filterDropdown(navConfigs, leaderGrowthDropdown, bothTrue);
};

const getAnnualGroupGrowthLabel = (navConfigs: NavData[]): string => {
  const annualGroupGrowthActive = navConfigs.some(
    (NavDropdownItem) => NavDropdownItem.awardNumber === AWARD_CODES.ANNUAL_PGG && NavDropdownItem.programActive,
  );
  const monthlyGroupGrowthActive = navConfigs.some(
    (NavDropdownItem) => NavDropdownItem.awardNumber === AWARD_CODES.MONTHLY_PGG && NavDropdownItem.programActive,
  );
  if (annualGroupGrowthActive && monthlyGroupGrowthActive) {
    return ROUTE_LABELS.ANNUAL_PGG;
  }
  return ROUTE_LABELS.PERSONAL_GROUP_GROWTH;
};

export const getPerformanceIncentive = (
  navConfigs: NavData[],
): { performanceLabel: string; performanceCode: number } => {
  let performanceLabel = ROUTE_LABELS.PERFORMANCE_PLUS;
  let performanceCode = AWARD_CODES.PERFORMANCE_PLUS;
  const performancePlusActive = navConfigs.some(
    (NavDropdownItem) => NavDropdownItem.awardNumber === AWARD_CODES.PERFORMANCE_PLUS && NavDropdownItem.programActive,
  );
  const performanceEliteActive = navConfigs.some(
    (NavDropdownItem) => NavDropdownItem.awardNumber === AWARD_CODES.PERFORMANCE_ELITE && NavDropdownItem.programActive,
  );

  if (performancePlusActive) {
    performanceLabel = ROUTE_LABELS.PERFORMANCE_PLUS;
    performanceCode = AWARD_CODES.PERFORMANCE_PLUS;
  }
  if (performanceEliteActive) {
    performanceLabel = ROUTE_LABELS.PERFORMANCE_ELITE;
    performanceCode = AWARD_CODES.PERFORMANCE_ELITE;
  }
  if (performancePlusActive && performanceEliteActive) {
    performanceLabel = ROUTE_LABELS.PPE;
    performanceCode = AWARD_CODES.PERFORMANCE_ELITE;
  }
  return {
    performanceLabel,
    performanceCode,
  };
};
