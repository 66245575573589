import { AWARD_CODES } from "../../common/enums/award-codes";
import { DROPDOWN_KEYS, DROPDOWN_LABELS, ROUTE_LABELS, ROUTE_PATHS } from "../../common/enums/routing-enums";
import { NavDropdown } from "../../common/interfaces/routing";

/**
 * Needed for initial load of App. Continue to add pages to the dropdown items as they are added. This should never actually display
 * but React needs know the pages and their routes.
 */
export const defaultMenuConfig: NavDropdown[] = [
  {
    label: DROPDOWN_LABELS.PERFORMANCE,
    key: DROPDOWN_KEYS.PERFORMANCE,
    items: [
      {
        label: ROUTE_LABELS.PERFORMANCE_DASHBOARD,
        path: ROUTE_PATHS.PERFORMANCE_DASHBOARD,
        class: ROUTE_PATHS.PERFORMANCE_DASHBOARD,
        code: AWARD_CODES.MONTHLY_PERFORMANCE,
        navPosition: 0,
      },
      {
        label: ROUTE_LABELS.PERFORMANCE_BONUS,
        path: ROUTE_PATHS.PERFORMANCE_BONUS,
        class: ROUTE_PATHS.PERFORMANCE_BONUS,
        code: AWARD_CODES.PERFORMANCE_BONUS,
        navPosition: 2,
      },
      {
        label: ROUTE_LABELS.EARNINGS_CALCULATOR,
        path: ROUTE_PATHS.EARNINGS_CALCULATOR,
        class: ROUTE_PATHS.EARNINGS_CALCULATOR,
        code: AWARD_CODES.EARNINGS_CALCULATOR,
        navPosition: 3,
      },
    ],
  },
  {
    label: DROPDOWN_LABELS.EARLY_INCENTIVES,
    key: DROPDOWN_KEYS.EARLY_INCENTIVES,
    items: [
      {
        label: ROUTE_LABELS.PATH_TO_BRONZE,
        path: ROUTE_PATHS.PATH_TO_BRONZE,
        class: ROUTE_PATHS.PATH_TO_BRONZE,
        code: AWARD_CODES.PATH_TO_BRONZE,
        navPosition: 0,
      },
      {
        label: ROUTE_LABELS.STRONG_START_INCENTIVE,
        path: ROUTE_PATHS.STRONG_START_INCENTIVE,
        class: ROUTE_PATHS.STRONG_START_INCENTIVE,
        code: AWARD_CODES.STRONG_START_INCENTIVE,
        navPosition: 1,
      },
      {
        label: ROUTE_LABELS.CSI,
        path: ROUTE_PATHS.CSI,
        class: ROUTE_PATHS.CSI,
        code: AWARD_CODES.CSI,
        navPosition: 2,
      },
      {
        label: ROUTE_LABELS.BF_PLUS,
        path: ROUTE_PATHS.BRONZE_FOUNDATION,
        class: ROUTE_PATHS.BRONZE_FOUNDATION,
        code: AWARD_CODES.BRONZE_FOUNDATION,
        navPosition: 3,
      },
      {
        label: ROUTE_LABELS.BB_PLUS,
        path: ROUTE_PATHS.BRONZE_BUILDER,
        class: ROUTE_PATHS.BRONZE_BUILDER,
        code: AWARD_CODES.BRONZE_BUILDER,
        navPosition: 4,
      },
      {
        label: ROUTE_LABELS.BRONZE_INCENTIVES,
        path: ROUTE_PATHS.BRONZE_INCENTIVES,
        class: ROUTE_PATHS.BRONZE_INCENTIVES,
        code: AWARD_CODES.BRONZE_INCENTIVES,
        navPosition: 5,
      },
      {
        label: ROUTE_LABELS.BRONZE_INCENTIVES,
        path: ROUTE_PATHS.BRONZE_INCENTIVES,
        class: ROUTE_PATHS.BRONZE_INCENTIVES,
        code: AWARD_CODES.BRONZE_INCENTIVES,
        navPosition: 6,
      },
      {
        label: ROUTE_LABELS.QC_GROWTH,
        path: ROUTE_PATHS.QC_GROWTH,
        class: ROUTE_PATHS.QC_GROWTH,
        code: AWARD_CODES.QCG,
        navPosition: 7,
      },
    ],
  },
  {
    label: DROPDOWN_LABELS.MY_GROUP,
    key: DROPDOWN_KEYS.MY_GROUP,
    items: [
      {
        label: ROUTE_LABELS.LOS,
        path: ROUTE_PATHS.LOS,
        class: ROUTE_PATHS.LOS,
        code: AWARD_CODES.LOS,
        navPosition: 0,
      },
      {
        label: ROUTE_LABELS.EDLOS,
        path: ROUTE_PATHS.EDLOS,
        class: ROUTE_PATHS.EDLOS,
        code: AWARD_CODES.EDLOS,
        navPosition: 1,
      },
      {
        label: ROUTE_LABELS.ACTION_REPORTS,
        path: ROUTE_PATHS.ACTION_REPORTS,
        class: ROUTE_PATHS.ACTION_REPORTS,
        code: AWARD_CODES.ACTION_REPORTS,
        navPosition: 2,
      },
      {
        label: ROUTE_LABELS.WATCHLIST,
        path: ROUTE_PATHS.WATCHLIST,
        class: ROUTE_PATHS.WATCHLIST,
        code: AWARD_CODES.WATCHLIST,
        navPosition: 3,
      },
      {
        label: ROUTE_LABELS.BRONZE_GROUP_TRACKING,
        path: ROUTE_PATHS.BRONZE_GROUP_TRACKING,
        class: ROUTE_PATHS.BRONZE_GROUP_TRACKING,
        code: AWARD_CODES.BRONZE_GROUP_TRACKING,
        navPosition: 5,
      },
    ],
  },
  {
    label: DROPDOWN_LABELS.LEADER_GROWTH,
    key: DROPDOWN_KEYS.LEADER_GROWTH,
    items: [
      {
        label: ROUTE_LABELS.PERFORMANCE_PLUS,
        path: ROUTE_PATHS.PPE,
        class: ROUTE_PATHS.PPE,
        code: AWARD_CODES.PERFORMANCE_PLUS,
        navPosition: 0,
      },
      {
        label: ROUTE_LABELS.MONTHLY_PGG,
        path: ROUTE_PATHS.MONTHLY_PGG,
        class: ROUTE_PATHS.MONTHLY_PGG,
        code: AWARD_CODES.MONTHLY_PGG,
        navPosition: 1,
      },
      {
        label: ROUTE_LABELS.ANNUAL_PGG,
        path: ROUTE_PATHS.ANNUAL_PGG,
        class: ROUTE_PATHS.ANNUAL_PGG,
        code: AWARD_CODES.ANNUAL_PGG,
        navPosition: 2,
      },
      {
        label: ROUTE_LABELS.PERSONAL_GROUP_GROWTH,
        path: ROUTE_PATHS.PGG_GCR,
        class: ROUTE_PATHS.PGG_GCR,
        code: AWARD_CODES.PGG_GCR,
        navPosition: 3,
      },
      {
        label: ROUTE_LABELS.FGG,
        path: ROUTE_PATHS.FGG,
        class: ROUTE_PATHS.FGG,
        code: AWARD_CODES.FGG,
        navPosition: 4,
      },
      {
        label: ROUTE_LABELS.TTCI,
        path: ROUTE_PATHS.TWO_TIME_CASH_INCENTIVE,
        class: ROUTE_PATHS.TWO_TIME_CASH_INCENTIVE,
        code: AWARD_CODES.TWO_TIME_CASH_INCENTIVE,
        navPosition: 5,
      },
      {
        label: ROUTE_LABELS.HAT,
        path: ROUTE_PATHS.HAT,
        class: ROUTE_PATHS.HAT,
        code: AWARD_CODES.HAT,
        navPosition: 6,
      },
      {
        label: ROUTE_LABELS.FAA,
        path: ROUTE_PATHS.FAA,
        class: ROUTE_PATHS.FAA,
        code: AWARD_CODES.FAA,
        navPosition: 7,
      },
      {
        label: ROUTE_LABELS.LEADERSHIP_SEMINAR,
        path: ROUTE_PATHS.LEADERSHIP_SEMINAR,
        class: ROUTE_PATHS.LEADERSHIP_SEMINAR,
        code: AWARD_CODES.LSI,
        navPosition: 8,
      },
    ],
  },
  {
    label: DROPDOWN_LABELS.RESOURCES,
    key: DROPDOWN_KEYS.RESOURCES,
    items: [
      {
        label: ROUTE_LABELS.FEEDBACK,
        path: ROUTE_PATHS.FEEDBACK,
        class: ROUTE_PATHS.FEEDBACK,
        navPosition: 0,
      },
      {
        label: ROUTE_LABELS.LEARN_ABOUT_CPP,
        path: ROUTE_PATHS.LEARN_ABOUT_CPP,
        class: ROUTE_PATHS.LEARN_ABOUT_CPP,
        code: AWARD_CODES.LEARN_ABOUT_CPP,
        navPosition: 1,
      },
      {
        label: ROUTE_LABELS.LOG_OUT,
        path: ROUTE_PATHS.LOG_OUT,
        class: ROUTE_PATHS.LOG_OUT,
        navPosition: 2,
      },
    ],
  },
];
