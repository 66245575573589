import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../content/leadershipSeminar";
import "./LeadershipSeminar.scss";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { useAppSelector } from "../../store";
import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import { LSBanner } from "./LSBanner/LSBanner";
import { LSRequirements } from "./LSRequirements/LSRequirements";
import { FetchLSOverviewData } from "./LeadershipSeminarDataLayer";
import PQRequirements from "./PQRequirements/PQRequirements";
import { mockPQRequirementData } from "./mockLSData";

export function LeadershipSeminar() {
  const { t } = useTranslation(["leadershipSeminar"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const { lsOverviewData, isLoading, errorStatus } = FetchLSOverviewData(selectedPeriod);

  const { hideRequirements, Status, PQRequirementsData } = lsOverviewData;
  const totalRequirements = 3;
  const totalRequirementsMet = 3;

  const automaticallyApproved =
    Status === QUALIFICATION_STATUS.MANUALLY_QUALIFIED || Status === QUALIFICATION_STATUS.MANUALLY_APPROVED;

  return (
    <main className="LeadershipSeminar">
      <LSBanner Status={Status} isLoading={isLoading} />
      {automaticallyApproved ? (
        <></>
      ) : (
        <div className="LeadershipSeminar__pageTitle">
          <h1 className="LeadershipSeminar__pageTitle--title">
            <Trans>{`${t("pageTitle.title", String(defaultContent["pageTitle"]["title"]))}`}</Trans>
          </h1>
          <div className="LeadershipSeminar__pageTitle--timestamp">
            <TimeStamp dateFormat={"long"} />
          </div>
        </div>
      )}

      {automaticallyApproved ? (
        <></>
      ) : (
        <div className="LeadershipSeminar__requirementsSection">
          <h2 className="LeadershipSeminar__requirementsSection--title">
            {`${t("requirements.sectionHeader", String(defaultContent["requirements"]["sectionHeader"]))}`}
          </h2>
          <p className="LeadershipSeminar__requirementsSection--description">
            <Trans>
              {t("requirements.description", defaultContent.requirements["description"], {
                current: totalRequirementsMet,
                needed: totalRequirements,
              })}
              <span
                className={totalRequirements === totalRequirementsMet ? "Numbers__bold Numbers__met" : "Numbers__bold"}
              ></span>
            </Trans>
          </p>
          {hideRequirements ? <></> : <LSRequirements lsOverviewResponse={lsOverviewData} isLoading={isLoading} />}
          {PQRequirementsData.showPQRequirement ? (
            <div className="LeadershipSeminar__requirementsSection--pqTile">
              <PQRequirements
                pqRequirements={mockPQRequirementData}
                isLoading={isLoading}
                showPQRequirementsTile={PQRequirementsData.showPQRequirement}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}

      <div
        className={`LeadershipSeminar__footer ${
          automaticallyApproved ? "LeadershipSeminar--automaticallyApproved" : ""
        }`}
      >
        <BonusInfoFooter />
      </div>
    </main>
  );
}
