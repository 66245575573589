import { useTranslation } from "react-i18next";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import LeafTable from "../../../../../common/leaf/LeafTable/LeafTable";
import defaultContent from "../../../../../content/frontlineGrowth";
import { BaselineFqLeg } from "../../../../../common/interfaces/frontlineGroupGrowth";
import { getPerformanceYearShort, getSelectedPerformanceYearData } from "../../../../../common/util/period";
import { MultiplierDetailsModal } from "../../MutliplierDetails";
import { renderLoadingOrError } from "../../MultiplierDetailsUtil";
import "./BaselineFQModal.scss";

interface BaselineFQModalProps extends MultiplierDetailsModal {
  baselineFQLegs: BaselineFqLeg[];
  baselineFQs: string;
  selectedPeriod: string;
}

export const BaselineFQModal = ({
  baselineFQLegs,
  baselineFQs,
  selectedPeriod,
  toggleModal,
  displayModal,
  isLoading,
  errorStatus,
  refetch,
}: BaselineFQModalProps) => {
  const { t } = useTranslation(["frontlineGrowth"]);
  const { selectedPerformanceYear: onePYPrior } = getSelectedPerformanceYearData(selectedPeriod, 1);
  const { selectedPerformanceYear: twoPYsPrior } = getSelectedPerformanceYearData(selectedPeriod, 2);

  const getName = (BaselineDetails: BaselineFqLeg[], index: number) => {
    if (BaselineDetails[index].name) {
      return `${BaselineDetails[index].name} #${baselineFQLegs[index].aboNo}`;
    }
    return t("confidentialUser", defaultContent["confidentialUser"]) + " " + baselineFQLegs[index].aboNo;
  };

  const createRows = (BaselineDetails: BaselineFqLeg[]) => {
    if (BaselineDetails) {
      const rows = new Array(BaselineDetails.length);

      BaselineDetails.forEach((row, index) => {
        rows[index] = {
          row: [
            { value: getName(BaselineDetails, index) },
            { value: BaselineDetails[index].secondLastPYQualCount },
            { value: BaselineDetails[index].lastPYQualCount },
            { value: BaselineDetails[index].averageQualCount },
          ],
        };
      });

      return rows;
    }
    return [];
  };

  const rows = createRows(baselineFQLegs);

  const renderModalBody = () => {
    return (
      <div className="BaselineFQModal__body">
        <LeafTable
          header={[
            { value: t("abo", defaultContent.abo, {}), width: "55%" },
            {
              value: t("pyLabel", defaultContent.pyLabel, { performanceYear: getPerformanceYearShort(twoPYsPrior) }),
              width: "15%",
            },
            {
              value: t("pyLabel", defaultContent.pyLabel, { performanceYear: getPerformanceYearShort(onePYPrior) }),
              width: "15%",
            },
            { value: t("average", defaultContent.average, {}), width: "15%" },
          ]}
          rows={rows}
          columnAlign={"center"}
          headerStyle={"default"}
          hideHeader={false}
        />
      </div>
    );
  };

  return (
    <div className="BaselineFQModal">
      <LeafModal
        open={displayModal}
        onCloseCallbackFn={toggleModal}
        title={`${t("multiplierDetails.baseline", defaultContent.multiplierDetails.baseline)}`}
        subtitle={`${t("currentFqs", defaultContent.currentFqs, { fqs: baselineFQs })}`}
      >
        {!isLoading && !errorStatus ? (
          <>{renderModalBody()}</>
        ) : (
          <>{renderLoadingOrError(isLoading, errorStatus, refetch)}</>
        )}
      </LeafModal>
    </div>
  );
};
