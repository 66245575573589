import { useTranslation } from "react-i18next";
import { LegsPercentageTile } from "../../../../../common/components/RequirementTiles/LegsPercentageTile/LegsPercentageTile";
import defaultContent from "../../../../../content/bronzeRequirements";
import { ROUTE_PATHS } from "../../../../../common/enums/routing-enums";

type Requirement = {
  met: boolean;
  required: boolean;
  actual: number;
  target: number;
  short: number;
  targetVCSPV: number;
  formatted: {
    targetVCSPV: string;
  };
};

type Props = {
  isFetching: boolean;
} & Partial<Requirement>;

const BronzeRequirementVCSLegs = (props: Props) => {
  const { t } = useTranslation(["bfiRequirements"]);
  const {
    met = false,
    required = false,
    actual = 0,
    target = 0,
    short = 0,
    targetVCSPV = 0,
    formatted: { targetVCSPV: formattedTargetVCSPV = "" } = {},
    isFetching,
  } = props;
  const viewDownlineButtonOnClick = () => {
    window.open(ROUTE_PATHS.LOS, "_blank");
  };

  if (isFetching === false && required === false) {
    return <></>;
  }

  return (
    <LegsPercentageTile
      isLoading={isFetching}
      circleCheckmarkId={"legs-percentage"}
      met={met}
      title={`${t("vcsBonusLegsTitle", defaultContent["vcsBonusLegsTitle"], { vcspv: formattedTargetVCSPV })}`}
      actual={actual}
      target={target}
      short={short}
      viewDownlineButtonOnClick={viewDownlineButtonOnClick}
      viewDownlineButtonText={`${t("viewDownlineButton", defaultContent["viewDownlineButton"])}`}
    />
  );
};

export default BronzeRequirementVCSLegs;
