import { useState } from "react";
import { LSOverviewResponse } from "./LSRequirements/LSRequirements";
import {
  lsOverviewResponseKRDefault,
  lsOverviewResponseOptionsLayout,
  lsOverviewResponseMonthlyAnnualLayout,
  lsOverviewResponseKRNewFQs,
  lsOverviewResponseAutomaticallyApproved,
} from "./mockLSData";

export const FetchLSOverviewData = (period: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [lsOverviewData, setLsOverviewData] = useState<LSOverviewResponse>(lsOverviewResponseOptionsLayout);

  return { isLoading, lsOverviewData, errorStatus };
};
