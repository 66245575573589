import { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { LOS_GRID_EVENT } from "@amwaycommon/acc-wc-views/src/acc-wc-view-los-indented/enum.js";
import { mapAttributes } from "../../common/util/config";
import { Error, ERROR_TYPE } from "../Error/Error";
import { storedLocale } from "../impersonation/util";
import { useAppDispatch, useAppSelector } from "../../store";
import { showProfile } from "../../reducers/profile";
import ABOProfile from "../ABOProfile/ABOProfile";
import { useActionReportMenuOptions } from "./hooks/useActionReportMenuOptions";
import { getARComponentConfiguration } from "./utils/ActionReportHelpers";

const loadComponent = async () => {
  return import("@amwaycommon/acc-wc-los/acc-wc-los-ar.js");
};

export default function CbrActionReport() {
  const locale = storedLocale().toLowerCase();
  const dispatch = useAppDispatch();
  const [apiError, setApiError] = useState(false);
  const { actionReportMenuOptions } = useActionReportMenuOptions();
  const { configuration } = useAppSelector((state) => state.boot);
  const { cbrAr = {} } = getARComponentConfiguration(configuration);
  const { isPending } = useAsync({ promiseFn: loadComponent });
  const enableABOProfileSlider = configuration?.profile?.enableABOProfileSlider;
  const [modalOpenedByElement, setModalOpenedByElement] = useState<HTMLElement>();
  const updatedcbrAr = {
    ...cbrAr,
    uiGrid: cbrAr.uiGrid
      .split(",")
      .filter((item: string) => (enableABOProfileSlider === true ? item !== "uiMenuShowPerfHistory" : true))
      .join(","),
  };

  useEffect(() => {
    if (!isPending) {
      const arCbr = document.getElementById("acc_wc_los_ar_cbr_component");

      // Get market config for market-specific properties
      mapAttributes(arCbr, updatedcbrAr, {});

      // After all properties are added, render!
      // Render!
      const wrapper = document.getElementById("arCbr-wrapper") as HTMLDivElement;
      const cbrElements = document.getElementsByTagName("acc-wc-los-ar");
      // prevent multiple cbr instances being appended to the DOM
      if (!cbrElements.length && arCbr) {
        wrapper.append(arCbr);
      }
    }
  }, [isPending, cbrAr, actionReportMenuOptions, locale]);

  useEffect(() => {
    if (!apiError) {
      window.addEventListener("acc-api-error", () => setApiError(true));
    }

    if (apiError) {
      window.removeEventListener("acc-api-error", () => {});
    }
  }, [apiError]);

  useEffect(() => {
    window.addEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    return () => {
      window.removeEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    };
  }, []);

  const showABOProfile = (event: CustomEventInit) => {
    if (enableABOProfileSlider === true) {
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(event.detail.target);
    }
  };

  if (apiError) {
    return <Error errorType={ERROR_TYPE.GENERIC_ERROR} />;
  }

  const renderCbrAr = () => {
    if (!enableABOProfileSlider) {
      return (
        <acc-wc-los-ar
          id="acc_wc_los_ar_cbr_component"
          locale={locale.toLowerCase()}
          uiHeaderReportSelectorOptions={JSON.stringify(actionReportMenuOptions)}
        ></acc-wc-los-ar>
      );
    }
    return (
      <acc-wc-los-ar
        id="acc_wc_los_ar_cbr_component"
        locale={locale.toLowerCase()}
        uiHeaderReportSelectorOptions={JSON.stringify(actionReportMenuOptions)}
        enableABOProfileSlider
      ></acc-wc-los-ar>
    );
  };

  if (isPending) {
    return <></>;
  }
  return (
    <main>
      <div id="arCbr-wrapper">{renderCbrAr()}</div>
      <ABOProfile previousElement={modalOpenedByElement} useCurrentMonthData={true} />
    </main>
  );
}
