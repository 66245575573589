import { RefObject, useRef } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { BadgeRequirement, PercentRequirement, VolumeRequirement } from "../../../common/interfaces/coreplusIncentives";
import defaultContent from "../../../content/leadershipSeminar";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { VolumeRequirementTile } from "../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";
import { VcsPercentRequirementTile } from "../../../common/components/RequirementTiles/VcsPercentRequirementTile/VcsPercentRequirementTile";
import { BadgeRequirementTile } from "../../../common/components/RequirementTiles/BadgeRequirementTile/BadgeRequirementTile";
import { MonthlyRubyRequirement } from "../../../common/interfaces/groupGrowthIncentives";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import { CallResult } from "../../../common/interfaces/accApiWorkspace";
import { IconPlainTable } from "../../../common/icons/IconPlainTable/IconPlainTable";
import { FQsComparisonTile } from "../FQsComparisonTile/FQsComparisonTile";
import { PQRequirementsType } from "../../../common/interfaces/leadershipSeminar";
import { MonthlyAnnualLayout } from "./MonthlyAnnualLayout/MonthlyAnnualLayout";
import { OptionsLayout } from "./OptionsLayout/OptionsLayout";
import "./LSRequirements.scss";

type Props = {
  lsOverviewResponse: LSOverviewResponse;
  isLoading: boolean;
};

export interface FQRequirement {
  ShowFQRequirement: boolean;
  ShowFqTileByStack: boolean;
  FqStatusesMet: boolean[];
  TotalFqs: number;
  Met: boolean;
  StatusCount: string;
  TrackingCount: string;
  CurrentYtdCount: string;
}

export interface TempAnnualRequirements {
  VolumeRequirements: VolumeRequirement[];
  PercentRequirements: PercentRequirement[];
  BadgeRequirements?: BadgeRequirement[];
  FQRequirement?: FQRequirement;
  TotalRequirements: number;
  RequirementsMet: number;
  RequirementMetStatuses: boolean[];
}

export interface TempMonthlyRequirements {
  MonthlyRubyRequirement: MonthlyRubyRequirement[];
  BadgeRequirements: BadgeRequirement[];
  TotalRequirements: number;
  RequirementsMet: number;
  RequirementMetStatuses: boolean[];
}

export interface Option {
  AnnualRequirements: TempAnnualRequirements;
  MonthlyRequirements: TempMonthlyRequirements;
}

export interface Options {
  ShowOptions: boolean;
  FirstOption: Option;
  SecondOption: Option;
}

export interface LSOverviewResponse {
  Status: QUALIFICATION_STATUS;
  AnnualRequirements: TempAnnualRequirements;
  MonthlyRequirements: TempMonthlyRequirements;
  Options: Options;
  secondTime: boolean;
  hideRequirements: boolean;
  PQRequirementsData: PQRequirementsType;
  CallResults: CallResult[];
  Error: any;
}

export const createLoadingTiles = (totalRequirements: number) => {
  const numberOfLoaders = Math.min(totalRequirements, 3);
  const loaders = [];
  for (let i = 0; i < numberOfLoaders; i++) {
    loaders.push(<LeafTile isLoading={true} />);
  }
  return <>{loaders}</>;
};

export const LSRequirements = ({ lsOverviewResponse, isLoading }: Props) => {
  const { t } = useTranslation("leadershipSeminar");
  const { AnnualRequirements, MonthlyRequirements, Options, secondTime } = lsOverviewResponse;
  const buttonRef = useRef<HTMLButtonElement>(null);

  if (Options.ShowOptions) {
    return <OptionsLayout options={lsOverviewResponse.Options} isLoading={isLoading} />;
  }
  if (secondTime) {
    return <MonthlyAnnualLayout lsOverviewResponse={lsOverviewResponse} isLoading={isLoading} />;
  }

  if (isLoading) {
    const annualReqsCount = Math.min(AnnualRequirements.TotalRequirements, 1);
    const monthlyReqsCount = Math.min(MonthlyRequirements.TotalRequirements, 1);
    return createLoadingTiles(annualReqsCount + monthlyReqsCount);
  }

  return (
    <section className="LSRequirements">
      {MonthlyRequirements.MonthlyRubyRequirement.map((requirement) => {
        return (
          <section className="MonthlyAnnual__tile" key={requirement.Name} data-testid={requirement.Name + "-Tile"}>
            <VolumeRequirementTile
              isLoading={false}
              title={t(
                `requirements.${requirement.Name}.title`,
                defaultContent["requirements"][requirement.Name]["title"] as string,
                {
                  volumeMonthlyGpv: requirement.Volume.Target.Formatted,
                },
              )}
              currentAmount={requirement.Volume.Current.Actual}
              targetAmount={requirement.Volume.Target.Actual}
              met={requirement.RequirementsMet.NoLegPVMet}
              circleCheckmarkId={requirement.Name}
              volumeType={requirement.Name}
              labels={[requirement.Volume.Current.Formatted, requirement.Volume.Remaining.Formatted]}
              showLabelsOnly={true}
              additionalNeeded={requirement.Volume.Remaining.Formatted}
              exempt={requirement.Exempt}
              hideBorder={false}
              buttonData={{
                clickFunction: () => console.log("update this with modal open"),
                text: "Monthly GPV Status",
                ref: buttonRef,
                icon: <IconPlainTable />,
              }}
            />
          </section>
        );
      })}
      {MonthlyRequirements.BadgeRequirements.map((requirement) => {
        return (
          <section className="MonthlyAnnual__tile" key={requirement.Name} data-testid={requirement.Name + "-Tile"}>
            <BadgeRequirementTile
              title={t(
                `requirements.${requirement.Name}.title`,
                defaultContent["requirements"][requirement.Name]["title"] as string,
                {
                  bonusAmount: requirement.Target,
                },
              )}
              description={t(
                `requirements.${requirement.Name}.description`,
                defaultContent["requirements"][requirement.Name]["description"] as string,
              )}
              status={requirement.Status}
              showLabel={requirement.ShowLabel}
              hideBorder={false}
            />
          </section>
        );
      })}

      {AnnualRequirements.VolumeRequirements.map((requirement) => {
        return (
          <section key={requirement.Name} data-testid={origin + "Tile-" + requirement.Name}>
            <VolumeRequirementTile
              isLoading={false}
              title={t(
                `requirements.${requirement.Name}.title`,
                defaultContent["requirements"][requirement.Name]["title"] as string,
                {
                  volume: requirement.Volume.Target.Formatted,
                  volumeRuby: requirement.Volume.Target.Formatted,
                  volumeLegs: requirement.Legs ? requirement.Legs.Target.Formatted : "",
                },
              )}
              currentAmount={requirement.Volume.Current.Actual}
              targetAmount={requirement.Volume.Target.Actual}
              met={requirement.Met}
              circleCheckmarkId={requirement.Name}
              volumeType={requirement.Name}
              labels={[requirement.Volume.Current.Formatted, requirement.Volume.Remaining.Formatted]}
              showLabelsOnly={true}
              additionalNeeded={requirement.Volume.Remaining.Formatted}
              exempt={requirement.Exempt}
            />
          </section>
        );
      })}
      {AnnualRequirements.PercentRequirements.map((requirement) => {
        return (
          <section key={requirement.Name} data-testid={origin + "Tile-" + requirement.Name}>
            <VcsPercentRequirementTile
              isLoading={false}
              additionalNeeded={requirement.Percent.Remaining.Actual}
              targetValue={requirement.Percent.Target.Actual}
              maxValue={requirement.Percent.Target.Actual}
              met={requirement.Met}
              shouldShowGraphics={true}
              leftValue={requirement.Volume.Current.Actual}
              rightValue={requirement.Volume.Target.Actual}
              percent={requirement.Percent.Current.Actual}
            />
          </section>
        );
      })}
      {AnnualRequirements.BadgeRequirements ? (
        AnnualRequirements.BadgeRequirements.map((requirement) => {
          return (
            <section key={requirement.Name} data-testid={origin + "Tile-" + requirement.Name}>
              <BadgeRequirementTile
                title={t(
                  `requirements.${requirement.Name}.title`,
                  defaultContent["requirements"][requirement.Name]["title"] as string,
                  {
                    bonusAmount: requirement.Target,
                  },
                )}
                description={t(
                  `requirements.${requirement.Name}.description`,
                  defaultContent["requirements"][requirement.Name]["description"] as string,
                )}
                status={requirement.Status}
                showLabel={requirement.ShowLabel}
              />
            </section>
          );
        })
      ) : (
        <></>
      )}
      {AnnualRequirements.FQRequirement ? (
        <FQsComparisonTile
          isLoading={false}
          hideBorder={false}
          showFqTileByStack={AnnualRequirements.FQRequirement.ShowFqTileByStack}
          fqStatusesMet={AnnualRequirements.FQRequirement.FqStatusesMet}
          totalFqs={AnnualRequirements.FQRequirement.TotalFqs}
          met={AnnualRequirements.FQRequirement.Met}
          status={AnnualRequirements.FQRequirement.StatusCount}
          tracking={AnnualRequirements.FQRequirement.TrackingCount}
          currentYtd={AnnualRequirements.FQRequirement.CurrentYtdCount}
        />
      ) : (
        <></>
      )}
    </section>
  );
};
