import { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { LOS_GRID_EVENT } from "@amwaycommon/acc-wc-views/src/acc-wc-view-los-indented/enum.js";
import { mapAttributes, storedIsoCountry } from "../../common/util/config";
import { fetchContent, storedLocale } from "../impersonation/util";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../store";
import { showProfile } from "../../reducers/profile";
import { Error, ERROR_TYPE } from "../Error/Error";
import ABOProfile from "../ABOProfile/ABOProfile";
import { useActionReportMenuOptions } from "./hooks/useActionReportMenuOptions";
import { getARComponentConfiguration } from "./utils/ActionReportHelpers";

const loadComponent = async () => {
  return import("@amwaycommon/acc-wc-los/acc-wc-los-ar.js");
};

export default function WhosNewActionReport() {
  const locale = storedLocale();
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { configuration } = useAppSelector((state) => state.boot);
  const { whosNewAr = {} } = getARComponentConfiguration(configuration);
  const { actionReportMenuOptions } = useActionReportMenuOptions();
  const dispatch = useAppDispatch();
  const [apiError, setApiError] = useState(false);
  const isCountryCode = storedIsoCountry();
  const pageId = "los";
  const [disclaimer, setDisclaimer] = useState("");
  const { isPending } = useAsync({ promiseFn: loadComponent });
  const enableABOProfileSlider = configuration?.profile?.enableABOProfileSlider;
  const [modalOpenedByElement, setModalOpenedByElement] = useState<HTMLElement>();
  const updatedWhosNewAr = {
    ...whosNewAr,
    uiGrid: whosNewAr.uiGrid
      .split(",")
      .filter((item: string) => (enableABOProfileSlider === true ? item !== "uiMenuShowPerfHistory" : true))
      .join(","),
  };

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));

    fetchContent(pageId, isCountryCode, locale, {}).then((content) => {
      setDisclaimer(content.footerDisclaimerMD);
    });
  }, [locale, isCountryCode, dispatch]);

  useEffect(() => {
    if (!isPending) {
      const whosNewArComponent = document.getElementById("acc_wc_los_ar_whosnew_component") as HTMLElement;
      mapAttributes(whosNewArComponent, updatedWhosNewAr, {});
    }
  }, [isPending, whosNewAr]);

  useEffect(() => {
    if (!apiError) {
      window.addEventListener("acc-api-error", () => setApiError(true));
    }

    if (apiError) {
      window.removeEventListener("acc-api-error", () => {});
    }
  }, [apiError]);

  useEffect(() => {
    window.addEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    return () => {
      window.removeEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    };
  }, []);

  const showABOProfile = (event: CustomEventInit) => {
    if (enableABOProfileSlider === true) {
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(event.detail.target);
    }
  };

  if (apiError) {
    return <Error errorType={ERROR_TYPE.GENERIC_ERROR} />;
  }

  if (isPending) {
    return <></>;
  }

  const renderWhoseNewAr = () => {
    if (!enableABOProfileSlider) {
      return (
        <acc-wc-los-ar
          id="acc_wc_los_ar_whosnew_component"
          locale={locale.toLowerCase()}
          period={selectedPeriod}
          disclaimer={disclaimer}
          uiHeaderReportSelectorOptions={JSON.stringify(actionReportMenuOptions)}
        ></acc-wc-los-ar>
      );
    }
    return (
      <acc-wc-los-ar
        id="acc_wc_los_ar_whosnew_component"
        locale={locale.toLowerCase()}
        period={selectedPeriod}
        disclaimer={disclaimer}
        uiHeaderReportSelectorOptions={JSON.stringify(actionReportMenuOptions)}
        enableABOProfileSlider
      ></acc-wc-los-ar>
    );
  };

  return (
    <main>
      <div id="ar-whosnew-wrapper">{renderWhoseNewAr()}</div>
      <ABOProfile previousElement={modalOpenedByElement} />
    </main>
  );
}
