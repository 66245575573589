const content: { [key: string]: string } = {
  performanceBonus: "Performance Bonus",
  csi: "CSI+",
  watchlist: "Watchlist",
  performance: "Performance",
  earlyIncentives: "Early Incentives",
  myGroup: "My Group",
  leaderGrowth: "Leader Growth",
  resources: "Resources",
  monthlyPerformance: "Monthly Performance",
  annualPerformance: "Annual Performance",
  earningsCalculator: "Earnings Calculator",
  bronzeFoundation: "Bronze Foundation+",
  bronzeBuilder: "Bronze Builder+",
  cml: "CML",
  los: "LOS",
  edlos: "EDLOS",
  actionReports: "Action Reports",
  performancePlusElite: "PPE",
  performancePlus: "Performance Plus+",
  performanceElite: "Performance Elite+",
  personalGrowthAnnual: "Annual Personal Group Growth+",
  personalGrowthMonthly: "Monthly Personal Group Growth+",
  personalGroupGrowth: "Personal Group Growth+",
  frontlineGrowth: "Frontline Growth Incentive+",
  twoTimeCashIncentive: "TTCI+",
  feedback: "Provide Us Feedback",
  learnAboutCpp: "Learn About Core Plus+",
  hat: "HAT/GAR",
  faa: "FAA",
  customerOverview: "Customer Overview",
  newAboActivation: "Path to Bronze+",
  bronzeGroupTracking: "Bronze Group Tracking",
  mbi: "MBI",
  strongStartIncentive: "Strong Start Incentive+",
  bronzeIncentives: "Bronze Incentives",
  qcg: "QCG+",
};

export default content;
