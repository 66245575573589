import { useTranslation } from "react-i18next";
import "./QCGrowth.scss";
import defaultContent from "../../content/qcGrowth";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import { MULTIPLIER_DATA_TYPE, REQUIREMENT_NAME } from "../../common/enums/requirements";
import { RequirementTileMapper } from "../../common/components/RequirementTileMapper/RequirementTileMapper";
import TrackingProgressTile from "./TrackingProgressTile/TrackingProgressTile";
import QCGBannerTile from "./QCGBannerTile/QCGBannerTile";
import QCGIncentiveCalculation from "./QCGIncentiveCalculation/QCGIncentiveCalculation";

export const QCGrowth = () => {
  const { t } = useTranslation(["qcGrowth"]);

  // mock data that will be removed after adding the data layer
  const IncomeData = {
    PerformanceBonusLevelPercent: "",
    PotentialPYEarnings: "$105.00",
    IncentiveCap: "",
    Status: QUALIFICATION_STATUS.TRACKING,
  };

  const selectedPeriod = "202409";

  const requirements = {
    VolumeRequirements: [],
    PercentRequirements: [],
    BadgeRequirements: [
      {
        Name: REQUIREMENT_NAME.DIAMOND_BONUS,
        Met: false,
        Target: "1",
        Status: QUALIFICATION_STATUS.NOT_TRACKING,
        ShowLabel: true,
      },
      {
        Name: REQUIREMENT_NAME.MAINTAIN_GAR,
        Met: false,
        Target: "1",
        Status: QUALIFICATION_STATUS.NOT_TRACKING,
        ShowLabel: true,
      },
      {
        Name: REQUIREMENT_NAME.MAINTAIN_QC,
        Met: false,
        Target: "1",
        Status: QUALIFICATION_STATUS.NOT_TRACKING,
        ShowLabel: true,
      },
    ],
    TotalRequirements: 3,
    RequirementsMet: 0,
    RequirementMetStatuses: [false, false, false],
  };

  const requirements1 = {
    VolumeRequirements: [],
    PercentRequirements: [],
    BadgeRequirements: [
      {
        Name: REQUIREMENT_NAME.DIAMOND_BONUS,
        Met: false,
        Target: "1",
        Status: QUALIFICATION_STATUS.NOT_TRACKING,
        ShowLabel: true,
      },
      {
        Name: REQUIREMENT_NAME.MAINTAIN_GAR,
        Met: false,
        Target: "1",
        Status: QUALIFICATION_STATUS.NOT_TRACKING,
        ShowLabel: true,
      },
    ],
    TotalRequirements: 3,
    RequirementsMet: 0,
    RequirementMetStatuses: [false, false],
  };

  const requirements2 = {
    VolumeRequirements: [],
    PercentRequirements: [],
    BadgeRequirements: [
      {
        Name: REQUIREMENT_NAME.MAINTAIN_QC,
        Met: false,
        Target: "1",
        Status: QUALIFICATION_STATUS.NOT_TRACKING,
        ShowLabel: true,
      },
    ],
    TotalRequirements: 3,
    RequirementsMet: 0,
    RequirementMetStatuses: [false],
  };

  return (
    <main className="Qcg">
      <PageTitleSection title={`${t("pageTitle", defaultContent["pageTitle"])}`} />
      <div className="Qcg__container">
        <div className="Qcg__section-padding Qcg__container-row">
          <section className="MonthlyPgg__banner">
            <QCGBannerTile isLoading={false} incomeData={IncomeData} />
          </section>
          <section className="Qcg__tracking">
            <TrackingProgressTile
              isLoading={false}
              totalRequirements={requirements.TotalRequirements}
              totalRequirementsMet={requirements.RequirementsMet}
              requirementMetStatuses={requirements.RequirementMetStatuses}
              id="Tracking Progress Tile"
            />
          </section>
        </div>
        <div className="Qcg__section-padding Qcg__calculation">
          <div>
            <h2>{t("incentiveCalc.sectionHeader", defaultContent.incentiveCalc.sectionHeader).toString()}</h2>
          </div>
          <div className="Qcg__multiplication">
            <QCGIncentiveCalculation
              multiplierData={[
                {
                  Type: MULTIPLIER_DATA_TYPE.INCENTIVE,
                  Base: "$0.00",
                  Multiplier: "0%",
                  Total: "$0.00",
                },
              ]}
              isLoading={false}
              selectedPeriod={selectedPeriod}
            />
          </div>
        </div>

        <div className="Qcg__section-padding Qcg__requirements">
          <div>
            <h2>{t("requirements.sectionHeader", defaultContent.requirements.sectionHeader).toString()}</h2>
          </div>
          <div className="Qcg__requirements-content">
            <RequirementTileMapper isLoading={false} requirements={requirements1} origin="qcg" />
          </div>
          <div className="Qcg__requirements-content">
            <RequirementTileMapper isLoading={false} requirements={requirements2} origin="qcg" />
          </div>
        </div>
        <section className="Pgg__footer">
          <BonusInfoFooter />
        </section>
      </div>
    </main>
  );
};
