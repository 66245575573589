import { useTranslation } from "react-i18next";
import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import "./MultiplierBase.scss";
import defaultContent from "../../../content/frontlineGrowth";
import {
  BonusAwardDetail,
  FGGMultiplierBaseResponse,
  MultiplierBaseDetailsData,
} from "../../../common/interfaces/frontlineGroupGrowth";
import LeafTable from "../../../common/leaf/LeafTable/LeafTable";
import { ErrorTile } from "../../../common/components/ErrorTile/ErrorTile";
import LeafRectangleLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { IconCheckCircle } from "../../../common/icons/IconCheckCircle/IconCheckCircle";
import { AWARD_CODES } from "../../../common/enums/award-codes";
import { AwardProgressBadge } from "../../../common/components/AwardProgressBadge/AwardProgressBadge";
import { AWARD_PROGRESS } from "../../../common/enums/award-progress-codes";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";

type Props = {
  multiplierBaseReponseData: {
    multiplierBaseData: FGGMultiplierBaseResponse;
    isLoading: boolean;
    errorStatus: boolean;
    refetch: Function;
  };
  modalIsOpen: boolean;
  toggleModal: Function;
  performanceYear: string;
  multiplierBase: string;
};

const getTagStatus = (status: string): AWARD_PROGRESS => {
  if (status === QUALIFICATION_STATUS.TRACKING) {
    return AWARD_PROGRESS.TRACKING;
  } else if (status === QUALIFICATION_STATUS.QUALIFIED) {
    return AWARD_PROGRESS.ACHIEVED;
  } else {
    return AWARD_PROGRESS.NOT_TRACKING;
  }
};

const getAwardContentKey = (awardNumber: number) => {
  switch (awardNumber) {
    case AWARD_CODES.LEADERSHIP:
      return "leadershipBonus";
    case AWARD_CODES.FOSTER:
      return "fosterBonus";
    case AWARD_CODES.PERFORMANCE_BONUS:
      return "performanceBonus";
    case AWARD_CODES.RUBY:
      return "rubyBonus";
    case AWARD_CODES.DEPTH:
      return "depthBonus";
    default:
      return "";
  }
};

export const MultiplierBase = ({
  multiplierBaseReponseData,
  modalIsOpen,
  toggleModal,
  performanceYear,
  multiplierBase,
}: Props) => {
  const { t } = useTranslation(["frontlineGrowth"]);
  const { isLoading, errorStatus, refetch, multiplierBaseData } = multiplierBaseReponseData;
  const { Status, MultiplierBaseDetails } = multiplierBaseData;
  const createRows = (BonusAwardDetail: BonusAwardDetail[]) => {
    if (BonusAwardDetail && BonusAwardDetail.length > 0) {
      const rows = new Array(BonusAwardDetail.length);
      BonusAwardDetail.forEach((_, index) => {
        const bonusAward = getAwardContentKey(BonusAwardDetail[index].BonusAward);
        const bonusAwardContent = `${t(
          `multiplierBase.${bonusAward}`,
          defaultContent["multiplierBase"][`${bonusAward}`],
          {
            performanceYear: performanceYear,
          },
        )}`;
        rows[index] = {
          row: [
            { value: bonusAwardContent, columnAlign: "left" },
            { value: BonusAwardDetail[index].BonusTotal, columnAlign: "left" },
          ],
        };
      });

      return rows;
    }
    return [];
  };

  const renderLoadingOrError = () => {
    if (isLoading) {
      return (
        <LeafRectangleLoader isLoading={isLoading} numRows={2} width="100%" height="6rem" margin={"1rem 0 0.5rem 0"} />
      );
    }
    if (errorStatus) {
      return <ErrorTile clickFunction={refetch} errorStatus={errorStatus} isLoading={isLoading} />;
    }
    return <></>;
  };

  const returnDividerLine = (index: number, multiplierBaseDetails: MultiplierBaseDetailsData[]) => {
    const activeSections = multiplierBaseDetails.filter((d) => d.DisplaySectionHeader).length;
    if (activeSections > 1 && index !== multiplierBaseDetails.length - 1) {
      return <span className="MultiplierBase__divider-line"></span>;
    }
    return <></>;
  };

  const renderComponent = () => {
    return (
      <div>
        <section className="MultiplierBase__header-container">
          <h2>{`${t("multiplierBase.title", defaultContent["multiplierBase"]["title"], {
            performanceYear: performanceYear,
          })}`}</h2>
          <AwardProgressBadge status={getTagStatus(Status)} hideIcon={true} />
        </section>
        {MultiplierBaseDetails.map((base, index) => {
          if (base.BonusAwards && base.BonusAwards.length > 0) {
            return (
              <div className={`MultiplierBase__multiplier-section`} key={index}>
                <section>
                  {base.DisplaySectionHeader ? (
                    <div>
                      <IconCheckCircle color={base.Achieved ? "green" : "gray"} size="md" solidCheckCircle={false} />
                      <p className={`${base.Achieved ? "MultiplierBase__multiplier-section__green" : ""}`}>{`${t(
                        `multiplierBase.${base.MultiplierType}`,
                        defaultContent["multiplierBase"][`${base.MultiplierType}`],
                      )}`}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <LeafTable
                    header={[
                      {
                        value: t("multiplierBase.bonus", defaultContent["multiplierBase"]["bonus"], {}),
                        width: "70%",
                        padding: "0",
                      },
                      {
                        value: t("multiplierBase.amount", defaultContent["multiplierBase"]["amount"], {}),
                        width: "30%",
                      },
                    ]}
                    rows={createRows(base.BonusAwards)}
                    columnAlign={"left"}
                    headerStyle={"clear"}
                    hideHeader={false}
                  />
                </section>
                {returnDividerLine(index, MultiplierBaseDetails)}
              </div>
            );
          }
          return <></>;
        })}
        <section className="MultiplierBase__total-container">
          <p>{`${t("multiplierBase.totalMultiplierBase", defaultContent["multiplierBase"]["totalMultiplierBase"])}`}</p>
          <span>{multiplierBase}</span>
        </section>
      </div>
    );
  };

  return (
    <div id="multiplier-base" className="MultiplierBase">
      <LeafModal open={modalIsOpen} onCloseCallbackFn={toggleModal}>
        {!isLoading && !errorStatus ? <>{renderComponent()}</> : <>{renderLoadingOrError()}</>}
      </LeafModal>
    </div>
  );
};
