import { useEffect } from "react";
import { AWARD_CODES } from "../../../common/enums/award-codes";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getLoginAffAbo } from "../../../reducers/model/boot";
import { useGetBronzeProgramStatusQuery } from "../../../services/bronzeIncentivesAPI";
import { updateProgramStatusDetails } from "../../../reducers/bronzeIncentive";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { storedLocale } from "../../impersonation/util";
import BronzeDisplayRules from "../BronzeCommon/BronzeDisplayRules/BronzeDisplayRules";
import { SELECTOR_TYPE, updateSelectorType } from "../../../reducers/period";
import BFIBannerTile from "./BFIBannerTile/BFIBannerTile";
import BFIIncentiveProgress from "./BFIIncentiveProgress/BFIIncentiveProgress";
import BFIEligibilityBannerTile from "./BFIEligibilityBannerTile/BFIEligibilityBannerTile";
import BFIRequirements from "./BFIRequirements/BFIRequirements";
import BFIMetric from "./BFIMetric/BFIMetric";
import BFIConsistencyPaymentMetric from "./BFIConsistencyPaymentMetric/BFIConsistencyPaymentMetric";
import "./BronzeFoundation.scss";

const BronzeFoundation = () => {
  const boot = useAppSelector(({ boot }) => boot);
  const locale = storedLocale();
  const dispatch = useAppDispatch();
  const affAbo = getLoginAffAbo({ boot });
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const { bronzeRestart = false } = boot?.configuration?.corePlus || {};

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  const { data, isFetching } = useGetBronzeProgramStatusQuery({
    affAbo,
    period: selectedPeriod,
    primaryAwardCode: AWARD_CODES.BRONZE_FOUNDATION,
    fixedPayment: AWARD_CODES.BRONZE_FOUNDATION_FIXED_PAYMENT,
    restart1: bronzeRestart === true ? AWARD_CODES.RESTART_BRONZE_FOUNDATION : void 0,
    restart2: bronzeRestart === true ? AWARD_CODES.RESTART_TWO_BRONZE_FOUNDATION : void 0,
  });

  if (isFetching === false && data) {
    const { awardCode, isFixedPayment, fixedPaymentAwardCode } = data;

    dispatch(updateProgramStatusDetails(data));

    return (
      <BronzeDisplayRules affAbo={affAbo} period={selectedPeriod} awardCode={awardCode} locale={locale}>
        {({ displayBannerOnly, displayEligibleBannerOnly, showAllBannerTiles }) => (
          <div
            className={`BronzeFoundation BronzeFoundation__${
              displayBannerOnly ? "banner-only" : ""
            } BronzeFoundation__${displayEligibleBannerOnly ? "eligible-banner-only" : ""}`}
          >
            <div className="BronzeFoundation__overview">
              <div className={`BronzeFoundation__overview__banner`}>
                <BFIBannerTile
                  awardCode={awardCode}
                  isFixedPayment={isFixedPayment}
                  isCompactView={displayBannerOnly === false}
                  fixedPaymentAwardCode={fixedPaymentAwardCode}
                  show={displayEligibleBannerOnly === false}
                />
              </div>
              <div className={`BronzeFoundation__overview__tracking`}>
                <BFIIncentiveProgress awardCode={awardCode} show={showAllBannerTiles} />
              </div>
              <div className={`BronzeFoundation__overview__eligible`}>
                <BFIEligibilityBannerTile awardCode={awardCode} show={displayBannerOnly === false} />
              </div>
            </div>
            <div className="BronzeFoundation__incentive-calculation">
              <BFIMetric awardCode={awardCode} show={showAllBannerTiles} />
              <BFIConsistencyPaymentMetric
                awardCode={awardCode}
                isFixedPayment={isFixedPayment}
                fixedPaymentAwardCode={fixedPaymentAwardCode}
                show={showAllBannerTiles}
              />
            </div>
            <div className="BronzeFoundation__requirements">
              <BFIRequirements awardCode={awardCode} show={showAllBannerTiles} />
            </div>
          </div>
        )}
      </BronzeDisplayRules>
    );
  }
  return <LeafTile isLoading={isFetching} />;
};

export default BronzeFoundation;
