const content: { [key: string]: string } = {
  eligibility: "Eligibility",
  eligibleDescription:
    "Eligibility begins the first month you earn the Bronze Foundation Incentive+ and lasts for {months} consecutive months. Maximum of 12 payments.",
  startDateLabel: "Start Date",
  endDateLabel: "End Date",
  footerTitle: "Program Details",
  notStartedMessage: "Start and end date will be defined at the end of your first month.",
  footerDescription:
    "Bronze Foundation and Builder Incentives+ reward you for coaching new business owners you sponsor to sell products while you grow your volume, too. The higher your Performance Bonus, the higher your Bronze Incentives.",
};

export default content;
