const content: { [key: string]: any } = {
  pageTitle: "Monthly Personal Group Growth+",
  monthlyPGGI: "Monthly PGGI",
  monthlyIncentiveMultiplier: "Monthly Incentive Multiplier",
  ytdTotal: "YTD Total",
  shortPerformanceYear: "PY{shortPY}",
  bannerTile: {
    earned: "Great Job! You are earning the Monthly Personal Group Growth+.",
    tracking: "Keep going to earn the Monthly Personal Group Growth+!",
    notTracking: "You are not currently tracking to achieve the Monthly Personal Group Growth+ for {month}.",
    noLongerEligible: "It is no longer possible for you to achieve the Monthly Personal Group Growth+ for {month}.",
    description:
      "Earn extra on the way to Founders and beyond. At the end of the Performance Year, the Monthly Personal Group Growth+ rewards you with a Performance Bonus multiplier based on the months you qualified.",
    manuallyApproved: "The Monthly Personal Group Growth+ has been manually approved.",
    potentialEarnings: "Potential {month} Earnings",
    incentiveCap: "Incentive is capped at {bonusCap}",
    contactCustomerService: "If you require more information, please contact Customer Support for further details.",
    held: "Your award is on hold.",
    denied: "Your {month} business is not eligible for the Monthly Personal Group Growth+.",
  },
  sectionHeader: "How is this incentive calculated?",
  performanceBonus: "Performance Bonus",
  qualified: "Qualified",
  perfBonus: "Perf. Bonus",
  multiplier: "Multiplier",
  monthlyTotal: "Monthly Total",

  requirements: {
    sectionHeader: "How do I qualify? Here is the list of requirements.",
    ruby: {
      title: "{volumeRuby} Ruby PV or {volumeLegs} with 1 or more qualified legs",
      description: "",
    },
    "personalQMonth-Pgg": {
      title: "Tracking for Annual Personal Group Growth Incentive",
      description:
        "Must be tracking to earn the Annual Personal Group Growth Incentive during the month to earn this incentive.",
    },
  },
};

export default content;
