import { ReactNode } from "react";
import PathToBronze from "../../PathToBronze/PathToBronze";
import BronzeFoundation from "../../BronzeFoundation/BronzeFoundation";
import BronzeBuilder from "../../BronzeBuilder/BronzeBuilder";
import { useAppSelector } from "../../../../store";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import { isProgramActiveBulkCheck } from "../../../../common/util/checkProgramActiveStatus";

export type BronzeView = {
  id: string;
  BronzeView: ReactNode;
  selected: boolean;
  group: number[];
};

export enum BRONZE_VIEW_IDS {
  PTB = "pathtobronze",
  BFI = "bronzefoundation",
  BBI = "bronzebuilder",
}

const BronzeIncentivesViews = (): BronzeView[] => {
  const eligibleIncentives = useAppSelector((store) => store?.boot?.shellConfig?.eligibleIncentives);

  const { BRONZE_FOUNDATION, BRONZE_BUILDER, PATH_TO_BRONZE } = AWARD_CODES;

  const bronzeView = [
    {
      id: BRONZE_VIEW_IDS.PTB,
      BronzeView: <PathToBronze showHeaderFooter={false} />,
      selected: false,
      group: [PATH_TO_BRONZE],
    },
    {
      id: BRONZE_VIEW_IDS.BFI,
      BronzeView: <BronzeFoundation />,
      selected: false,
      group: [BRONZE_FOUNDATION],
    },
    {
      id: BRONZE_VIEW_IDS.BBI,
      BronzeView: <BronzeBuilder />,
      selected: false,
      group: [BRONZE_BUILDER],
    },
  ]
    .filter(({ group }) => isProgramActiveBulkCheck(eligibleIncentives, group))
    .map((item, index) => ({
      ...item,
      selected: index === 0,
    }));

  return bronzeView as BronzeView[];
};

export default BronzeIncentivesViews;
