import { useTranslation } from "react-i18next";
import { LegsPercentageTile } from "../../../../../common/components/RequirementTiles/LegsPercentageTile/LegsPercentageTile";
import { ROUTE_PATHS } from "../../../../../common/enums/routing-enums";
import defaultContent from "../../../../../content/bronzeRequirements";

type ThreePctLegs = {
  met: boolean;
  required: boolean;
  actual: number;
  target: number;
  short: number;
  percent: number;
  formatted: {
    percent: string;
  };
};

type Props = {
  isFetching: boolean;
} & Partial<ThreePctLegs>;

const BronzeRequirementLegsPercent = (props: Props) => {
  const { t } = useTranslation(["bfiRequirements"]);
  const {
    met = false,
    required = true,
    actual = 0,
    target = 0,
    short = 0,
    formatted: { percent: formattedPercent } = {},
    isFetching = false,
  } = props;

  const viewDownlineButtonOnClick = () => {
    window.open(ROUTE_PATHS.LOS, "_blank");
  };

  if (required === true) {
    return (
      <LegsPercentageTile
        isLoading={isFetching}
        circleCheckmarkId={"legs-percentage"}
        met={met}
        title={`${t("legsPercentTitle", defaultContent["legsPercentTitle"], {
          leg: target,
          percent: formattedPercent,
        })}`}
        actual={actual}
        short={short}
        target={target}
        viewDownlineButtonOnClick={viewDownlineButtonOnClick}
        viewDownlineButtonText={`${t("viewDownlineButton", defaultContent["viewDownlineButton"])}`}
      />
    );
  }
  return <></>;
};

export default BronzeRequirementLegsPercent;
