import { useCallback, useState } from "react";
import "./MonthlyPerformanceProgressBar.scss";
import { deviceInfo } from "../../../../../common/util/browserSupport";

type ProgressValue = {
  actual: number;
  display: string;
};

type ProgressValues = {
  current: ProgressValue;
  target: ProgressValue;
};

type Props = {
  progressValues: ProgressValues;
  startLabel: string;
  startValueStr: string;
  endLabel: string;
  targetValueStr: string;
  legInfo?: string;
  progressMilestones?: number[];
  hideCaret?: boolean;
  showMaxFill?: boolean;
};

export const MonthlyPerformanceProgressBar = ({
  startLabel,
  startValueStr,
  endLabel,
  targetValueStr,
  legInfo,
  progressMilestones,
  progressValues,
  hideCaret = false,
  showMaxFill = false,
}: Props) => {
  let progress = Math.min((progressValues.current.actual / progressValues.target.actual) * 100, 100);

  if (showMaxFill) {
    progress = 100;
  }
  // When bar has currentValue is zero a small blue is added to the bar. hence 0.5 value added to the progress bar
  const progressBarWidth = `${progress > 0 ? progress : progress === 0 ? 0.5 : 0}%`;
  const [alignBarText, setAlignBarText] = useState(`${progress}%`);
  const [barTextClass, setBarTextClass] = useState("");
  const extraSpaceBesideTextInPerc = 1;
  const progressForCalc = progress > 0 ? progress : 0;

  const mileStoneWidth = (milestone: number): string => {
    return `${(milestone / progressValues.target.actual) * 100}%`;
  };

  // Adding or removing extra space beside progress bar text if its to the left or right
  const barTextRef = useCallback(
    (node: any) => {
      let additionalClass = "";
      const bigProgress = 95;
      const smallProgress = 21;
      const widthTwoLines = 55;
      const widthOneLine = 85;
      if (node !== null && progress >= 50) {
        additionalClass = "monthlyPerfProgressBar__long-bar";
        let nodeWidth = node.getBoundingClientRect().width;
        if (progress > bigProgress) {
          if (deviceInfo.platform.type === "mobile") {
            nodeWidth = (nodeWidth * widthOneLine) / widthTwoLines;
            additionalClass += " monthlyPerfProgressBar__long-space";
          }
        }
        setBarTextClass(additionalClass);
        const calcBarTextLeft = `calc(${progress - extraSpaceBesideTextInPerc}% - ${nodeWidth}px)`;
        setAlignBarText(calcBarTextLeft);
      } else {
        if (progress < smallProgress) {
          additionalClass = "monthlyPerfProgressBar__long-space";
        }
        setBarTextClass(additionalClass);
        setAlignBarText(`${progressForCalc + extraSpaceBesideTextInPerc}%`);
      }
    },
    [progress, progressForCalc],
  );

  const renderCaretNText = () =>
    hideCaret ? null : (
      <div className="monthlyPerfProgressBar__container">
        <div className="monthlyPerfProgressBar__caret" style={{ left: `${progressForCalc}%` }}></div>
        <span
          ref={barTextRef}
          className={"monthlyPerfProgressBar__bar-text " + barTextClass}
          style={{ left: alignBarText }}
        >
          <span>{progressValues.current.display}</span>
          {progressValues.target.display ? (
            <span className="monthlyPerfProgressBar__bar-slash">&nbsp;/&nbsp;</span>
          ) : (
            <></>
          )}
          <span className="monthlyPerfProgressBar__bar-text-target">{`${
            progressValues.target.display ? `${progressValues.target.display}` : progressValues.target.display
          }`}</span>
        </span>
      </div>
    );

  const renderProgressBar = () => (
    <div className="monthlyPerfProgressBar__progress-contatiner">
      <div
        className="monthlyPerfProgressBar__progress-filled"
        style={{
          width: progressBarWidth,
        }}
      ></div>
      {/* Progress milestones */}
      {renderMileStoneMarks()}
    </div>
  );

  const renderMileStoneMarks = () =>
    progressMilestones?.map((milestone: number, index: number) => (
      <div
        key={index}
        className="monthlyPerfProgressBar__milestones"
        style={{
          left: mileStoneWidth(milestone),
        }}
      ></div>
    ));

  const renderLegInfo = () => legInfo && <span className="monthlyPerfProgressBar__leg">&nbsp;{legInfo}</span>;

  return (
    <div className="monthlyPerfProgressBar">
      {/* Caret indicator */}
      {renderCaretNText()}

      {/* Progress bar */}
      {renderProgressBar()}

      {/* Labels and values */}
      <div className="monthlyPerfProgressBar__labels-values">
        <div className="monthlyPerfProgressBar__labels-values--left">
          <span className="monthlyPerfProgressBar__label">{startLabel}</span>
          <span className="monthlyPerfProgressBar__value">{startValueStr}</span>
        </div>
        <div className="monthlyPerfProgressBar__labels-values--right">
          <span className="monthlyPerfProgressBar__value">
            <span>{targetValueStr}</span> {renderLegInfo()}
          </span>
          <span className="monthlyPerfProgressBar__label">{endLabel}</span>
        </div>
      </div>
    </div>
  );
};
