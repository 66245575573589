import { useTranslation } from "react-i18next";
import { VolumeRequirementTile } from "../../../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";
import defaultContent from "../../../../../content/bronzeRequirements";

type Requirement = {
  met: boolean;
  required: boolean;
  actual: string;
  target: string;
  requiredGPV: number;
  currentGPV: number;
  shortGPV: number;
  formatted: {
    target: string;
    currentGPV: string;
    shortGPV: string;
  };
};

type Props = {
  isFetching: boolean;
} & Partial<Requirement>;

const BronzeRequirementPerfBonusLV = (props: Props) => {
  const { t } = useTranslation(["bfiRequirements"]);

  const {
    met = false,
    required = false,
    target = 0,
    requiredGPV = 0,
    currentGPV = 0,
    shortGPV = 0,
    formatted: {
      target: formattedTarget = "",
      currentGPV: formattedCurrentGPV = "",
      shortGPV: formattedShortGPV = "",
    } = {},
    isFetching,
  } = props;

  if (isFetching === false && required === false) {
    return <></>;
  }

  return (
    <VolumeRequirementTile
      title={`${t("perfBonusLevelTitle", defaultContent["perfBonusLevelTitle"], {
        perfBonus: formattedTarget,
      })}`}
      additionalNeeded={formattedShortGPV}
      met={met}
      isLoading={isFetching}
      currentAmount={currentGPV}
      targetAmount={requiredGPV}
      circleCheckmarkId={"volumeRequirementTile__gpv"}
      volumeType="group"
      showLabelsOnly={true}
      labels={[formattedCurrentGPV, formattedShortGPV]}
    />
  );
};

export default BronzeRequirementPerfBonusLV;
