import { formatReqVolume } from "@amwaycommon/acc-mixins/l10n";
import { useTranslation, Trans } from "react-i18next";
import { ReactNode, RefObject } from "react";
import { storedLocale } from "../../../../components/impersonation/util";
import { useAppSelector } from "../../../../store";
import LeafProgressCircle, { FormattedLabelValues } from "../../../leaf/LeafProgressCircle/LeafProgressCircle";
import LeafTag from "../../../leaf/LeafTag/LeafTag";
import LeafTile from "../../../leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/volumeRequirementTile";
import { LeafVolumeLabel } from "../../../leaf/LeafVolumeLabel/LeafVolumeLabel";
import "./VolumeRequirementTile.scss";

type ButtonData = {
  clickFunction: Function;
  text: string;
  ref: RefObject<HTMLButtonElement>;
  icon?: JSX.Element;
};

type Props = {
  title: string;
  progressLabel?: string;
  volumeNeededLabel?: ReactNode;
  isLoading: boolean;
  currentAmount: number;
  targetAmount: number;
  met: boolean;
  circleCheckmarkId: string;
  volumeType: string;
  additionalNeeded?: number | string;
  labels?: string[];
  showLabelsOnly?: boolean;
  exempt?: boolean;
  buttonData?: ButtonData;
  hideBorder?: boolean;
};

export const VolumeRequirementTile = ({
  isLoading,
  currentAmount,
  targetAmount,
  circleCheckmarkId,
  met,
  title,
  progressLabel,
  volumeNeededLabel,
  volumeType,
  additionalNeeded,
  labels,
  showLabelsOnly,
  exempt,
  buttonData,
  hideBorder = false,
}: Props) => {
  const { t } = useTranslation(["volumeRequirementTile"]);
  const { user } = useAppSelector((state) => state.boot);
  const locale = storedLocale();

  const subtitle = progressLabel
    ? progressLabel
    : t("progressLabel", defaultContent.progressLabel, {
        targetAmount: formatReqVolume(targetAmount, locale, user.isoCountryCode),
      });

  const volumeTypeLabel = LeafVolumeLabel(volumeType);

  return (
    <div className="LeafCircleProgressTile">
      <Trans>
        <LeafTile
          showMetStatus={true}
          circleCheckmarkId={circleCheckmarkId}
          met={met}
          title={title}
          isLoading={isLoading}
          hideBorder={hideBorder}
        >
          <div className="VolumeReqCircle">
            <span className="LeafCircleProgressTile__tag">
              <LeafTag hideTag={met} variant={"orange"}>
                {volumeNeededLabel ? (
                  volumeNeededLabel
                ) : (
                  <Trans
                    i18nKey="additionalNeeded"
                    defaults={defaultContent.additionalNeeded}
                    ns="volumeRequirementTile"
                    values={{
                      volumeNeeded: additionalNeeded
                        ? additionalNeeded
                        : formatReqVolume(targetAmount - currentAmount, locale, user.isoCountryCode),
                      volumeType: volumeTypeLabel,
                    }}
                    components={{ 1: <strong></strong> }}
                  />
                )}
              </LeafTag>
            </span>
            <LeafProgressCircle
              currentAmount={currentAmount}
              targetAmount={targetAmount}
              subtitle={subtitle}
              isLoading={isLoading}
              animationDuration={0}
              labels={labels}
              showLabelsOnly={showLabelsOnly}
            />
            {exempt && (
              <p className="VolumeReqCircle__exempt">
                {t("exemptLabel", defaultContent.exemptLabel, { volumeType: volumeTypeLabel })}
              </p>
            )}
            {buttonData ? (
              <div className="LeafCircleProgressTile__button-container">
                <button
                  className="LeafCircleProgressTile__button"
                  onClick={() => buttonData.clickFunction()}
                  ref={buttonData.ref}
                >
                  <span className="LeafCircleProgressTile__button-text">{buttonData.text}</span>
                  <span className="LeafCircleProgressTile__button-icon">{buttonData.icon}</span>
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </LeafTile>
      </Trans>
    </div>
  );
};
