import { Trans, useTranslation } from "react-i18next";
import { storedLocale } from "../../../components/impersonation/util";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { PQRequirementsType } from "../../../common/interfaces/leadershipSeminar";
import defaultContent from "../../../content/leadershipSeminar";
import "./PQRequirements.scss";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../../common/icons/IconShield/IconShield";
import { getShortMonth } from "../../../common/util/period";

type Props = {
  pqRequirements: PQRequirementsType;
  isLoading: boolean;
  showPQRequirementsTile: boolean;
};

export default function PQRequirements({ pqRequirements, isLoading, showPQRequirementsTile }: Props) {
  const { t } = useTranslation(["leadershipSeminar"]);
  const locale = storedLocale();

  const title = t("pqRequirementsTitle", defaultContent.pqRequirementsTitle, {
    achievedPqs: pqRequirements.achievedPqs,
    totalPqs: pqRequirements.totalPqs,
  });
  const metStatus = pqRequirements.status === QUALIFICATION_STATUS.QUALIFIED;

  const getGridLayout = () =>
    pqRequirements.pqHistory.some((pqRequirement) => getShortMonth(pqRequirement.period, locale).length > 5);

  const getIconShield = (qualStatus: QUALIFICATION_STATUS) => {
    switch (qualStatus) {
      case QUALIFICATION_STATUS.QUALIFIED:
        return BADGE_TYPE.EARNED;
      case QUALIFICATION_STATUS.NOT_QUALIFIED:
        return BADGE_TYPE.NOT_EARNED;
      default:
        return BADGE_TYPE.POTENTIAL;
    }
  };

  const renderLegend = () => {
    return (
      <div className="PQRequirements__legend">
        <div className="PQRequirements__legend--container PQRequirements__legend--first">
          <IconShield type={BADGE_TYPE.EARNED} />
          {`${t("legend.earned", defaultContent.legend["earned"])}`}
        </div>
        <div className="PQRequirements__legend--container">
          <IconShield />
          {`${t("legend.potential", defaultContent.legend["potential"])}`}
        </div>
        <div className="PQRequirements__legend--container">
          <IconShield type={BADGE_TYPE.NOT_EARNED} />
          {`${t("legend.notEarned", defaultContent.legend["notEarned"])}`}
        </div>
        <div className="PQRequirements__legend--container PQRequirements__legend--last">
          <IconShield type={BADGE_TYPE.UNAVAILABLE} />
          {`${t("legend.unavailable", defaultContent.legend["unavailable"])}`}
        </div>
      </div>
    );
  };

  const renderMonths = () => {
    return (
      <div className={`PQRequirements__months ${getGridLayout() ? "PQRequirements__months--grid-switched" : ""}`}>
        {pqRequirements.pqHistory.map((pqRequirement) => {
          return (
            <div className="PQRequirements__months--container">
              <IconShield size={BADGE_SIZE.LARGE} type={getIconShield(pqRequirement.status)} />
              {getShortMonth(pqRequirement.period, locale)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {showPQRequirementsTile ? (
        <>
          <LeafTile title={title} isLoading={isLoading} showMetStatus={true} met={metStatus}>
            <div className="PQRequirements">
              {renderLegend()}
              {renderMonths()}
            </div>
          </LeafTile>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
