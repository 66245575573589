import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./MonthlyPerformanceTile.scss";
import { useTranslation } from "react-i18next";
import { formatAboPV, formatReqVolume } from "@amwaycommon/acc-mixins/l10n";
import { IconTable } from "../../../../common/icons/IconTable/IconTable";
import { MonthlyPerformanceDashData } from "../../../../common/interfaces/monthlyPerformanceDash";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/monthlyPerformanceDashboard";
import { storedLocale } from "../../../impersonation/util";
import { useAppSelector } from "../../../../store";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import MonthlyPerformanceInfoModal from "../MonthlyPerformanceInfoModal/MonthlyPerformanceInfoModal";
import { addParams } from "../../../../common/util/addParams";
import { PerformanceBonusLevelText } from "./PerformanceBonusLevelText/PerformanceBonusLevelText";
import { MonthlyPerformanceProgressBar } from "./MonthlyPerformanceProgressBar/MonthlyPerformanceProgressBar";
import { MonthlyPerformanceTileLoader } from "./MonthlyPerformanceTileLoader/MonthlyPerformanceTileLoader";
import PerformanceBonusScheduleModal from "./PerformanceBonusScheduleModal/PerformanceBonusScheduleModal";

type Props = {
  showInfoModal: boolean;
  handleModalClose: Function;
  monthlyPerformanceData: MonthlyPerformanceDashData;
  isLoading: boolean;
  errorStatus: boolean;
  clickFunction: Function;
};

export const MonthlyPerformanceTile = ({
  showInfoModal,
  handleModalClose,
  monthlyPerformanceData,
  isLoading,
  errorStatus,
  clickFunction,
}: Props) => {
  const { t } = useTranslation(["monthlyPerformanceDashboard"]);
  const locale = storedLocale();
  const { user } = useAppSelector((state) => state.boot);
  const market = user.isoCountryCode;
  const { pvTargetWithOneQleg } = useAppSelector(
    (state) => state.boot.configuration.monthlyDashboard.monthlyPerformance,
  );

  const {
    CorePlanQualified,
    GroupPv,
    CurrentPerformanceBonusVolume,
    CurrentPerformanceBonusLevel,
    NextPerformanceBonusVolume,
    NextPerformanceBonusLevel,
    QualifiedLegs,
    Schedules: { bonusDetails = [] },
  } = monthlyPerformanceData;

  const getCurrentBonusDetails = () => {
    const maxPerfBonusPercent = monthlyPerformanceData.Schedules.bonusDetails[0].Percent;

    if (CorePlanQualified || maxPerfBonusPercent === CurrentPerformanceBonusLevel) {
      const clonedArr = [...bonusDetails];
      const secondHighestBonusSchedule = clonedArr.sort((a, b) => b.Volume - a.Volume)[1] || { Volume: 0, Percent: 0 };
      return {
        currentBonusLevelVolume: formatReqVolume(secondHighestBonusSchedule.Volume, locale, market),
        currentBonusLevelPercent: secondHighestBonusSchedule.Percent,
      };
    }
    return {
      currentBonusLevelVolume: formatReqVolume(CurrentPerformanceBonusVolume, locale, market),
      currentBonusLevelPercent: CurrentPerformanceBonusLevel,
    };
  };

  const { currentBonusLevelVolume, currentBonusLevelPercent } = getCurrentBonusDetails();
  const formattedGroupPV = formatAboPV(GroupPv, locale, market);
  const nextPbv = formatReqVolume(NextPerformanceBonusVolume, locale, market);
  const gpvString = `${t("gpv", defaultContent["gpv"])}`;
  const highestBonusLevelPercent = Math.max(...bonusDetails.map((item) => item.Percent));
  const maxPerfBonusPercent = monthlyPerformanceData.Schedules.bonusDetails[0].Percent;
  const maxPerfGpvTarget = formatAboPV(monthlyPerformanceData.Schedules.bonusDetails[0].Volume, locale, market);
  const pvTartgetOneLeg = formatAboPV(pvTargetWithOneQleg, locale, market);
  const [showPerformanceModal, setShowPerformanceModal] = useState(false);
  const prevOpenRef = useRef(false);
  const performanceModalButton = useRef<HTMLButtonElement>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const performanceModalParam = queryParams.get("performanceModal") === "true";
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    if (performanceModalParam && !showPerformanceModal) {
      handleIconTableClose();
    }
  }, [performanceModalParam, showPerformanceModal]);

  useEffect(() => {
    if (!!prevOpenRef.current && !showPerformanceModal && performanceModalButton && performanceModalButton.current) {
      performanceModalButton.current.focus();
    }
    prevOpenRef.current = showPerformanceModal;
  }, [showPerformanceModal]);

  const handleIconTableClick = () => {
    setShowPerformanceModal(true);
    addParams("performanceModal", "true", true, false);
  };

  const handleIconTableClose = () => {
    setShowPerformanceModal(false);
    addParams("performanceModal", "false", false, true);
  };

  const renderProgressBar = (qLegs: number) => {
    const hasSingleQLeg = qLegs === 1;
    const hasMultipleQLegs = qLegs >= 2;
    const startLabel = qLegs >= 1 ? 0 : currentBonusLevelPercent;
    const endLabel = qLegs >= 1 ? highestBonusLevelPercent : NextPerformanceBonusLevel;
    const targetValue = hasSingleQLeg ? pvTargetWithOneQleg : NextPerformanceBonusVolume;
    const legInfo = hasSingleQLeg ? `${t("withQLegs", defaultContent["withQLegs"], { qLegs: "1" })}` : undefined;
    const getProgressMilestones = () => {
      if (hasSingleQLeg) {
        return bonusDetails
          .filter(({ Volume }) => Volume <= pvTargetWithOneQleg)
          .map((schedule) => schedule.Volume)
          .sort((a, b) => a - b);
      }
      return [];
    };

    const getStartValueStr = () => {
      if (hasSingleQLeg) return `0 ${gpvString}`;
      if (CorePlanQualified && hasMultipleQLegs) return "";
      return `${currentBonusLevelVolume} ${gpvString}`;
    };

    const getTargetValueStr = () => {
      if (CorePlanQualified && hasMultipleQLegs)
        return `${t("withQLegs", defaultContent["withQLegs"], { qLegs: "2+" })}`;
      return `${nextPbv} ${gpvString}`;
    };

    return (
      <MonthlyPerformanceProgressBar
        startLabel={`${startLabel}%`}
        startValueStr={getStartValueStr()}
        endLabel={`${endLabel}%`}
        targetValueStr={getTargetValueStr()}
        legInfo={legInfo}
        hideCaret={hasMultipleQLegs}
        progressMilestones={getProgressMilestones()}
        showMaxFill={CorePlanQualified}
        progressValues={{
          current: { actual: GroupPv, display: formattedGroupPV },
          target: { actual: targetValue, display: getTargetValueStr() },
        }}
      />
    );
  };

  const renderMonthlyPerformanceTile = () => {
    if (isLoading) {
      return <MonthlyPerformanceTileLoader isLoading={isLoading} />;
    }
    if (errorStatus) {
      return <ErrorTile clickFunction={clickFunction} errorStatus={errorStatus} isLoading={isLoading} />;
    }

    return (
      <section>
        <div className="Mpd__section-title">
          <h2>{t("monthlyPerformance", defaultContent["monthlyPerformance"])}</h2>
          <button
            ref={performanceModalButton}
            aria-label={`${t("performanceBonusScheduleButton", defaultContent["performanceBonusScheduleButton"], {
              ns: "common",
            })}`}
            className="Mpd__section-title-button"
            onClick={handleIconTableClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            id="mpdIconTableButton"
          >
            <IconTable color={isHovered ? "blue" : ""} />
          </button>
        </div>
        <p className={"Mpd-Tile__Gpv-value"}>
          {formattedGroupPV} {gpvString}
        </p>
        <PerformanceBonusLevelText
          corePlanQualified={CorePlanQualified}
          currentBonusAmount={CurrentPerformanceBonusLevel}
        />
        {renderProgressBar(QualifiedLegs)}
      </section>
    );
  };

  return (
    <div>
      <LeafTile>{renderMonthlyPerformanceTile()}</LeafTile>
      <MonthlyPerformanceInfoModal
        maxPerfBonusPercent={maxPerfBonusPercent}
        maxPerfGpvTarget={maxPerfGpvTarget}
        pvTargetWithOneQleg={pvTartgetOneLeg}
        title={`${t("infoDrawerHeader", defaultContent["infoDrawerHeader"])}`}
        show={showInfoModal}
        isLoading={isLoading}
        onCloseClick={handleModalClose}
        id={"monthly-performance-info"}
        lockBackgroundScrolling={true}
      />
      <PerformanceBonusScheduleModal
        showPerformanceModal={showPerformanceModal}
        handleModalClose={handleIconTableClose}
        bonusDetails={bonusDetails}
      />
    </div>
  );
};
