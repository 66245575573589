import { useTranslation } from "react-i18next";
import { BonusHist, LeafBadgeGrid } from "../../../../../common/leaf/LeafBadgeGrid/LeafBadgeGrid";
import { useGetBronzeEligibilityQuery } from "../../../../../services/bronzeIncentivesAPI";
import { useAppSelector } from "../../../../../store";
import { storedLocale } from "../../../../impersonation/util";
import defaultContent from "../../../../../content/biEligibilitySlider";
import "./BIEligibilitySliderBody.scss";
import { getLoginAffAbo } from "../../../../../reducers/model/boot";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { getBronzeDisplayDate } from "../../BronzeDate/BronzeDate";

type Props = {
  isShowSlider: boolean;
  awardCode: number;
};

const BIEligibilitySliderBody = (props: Props) => {
  const { isShowSlider, awardCode } = props;
  const { t } = useTranslation(["biEligibilitySlider"]);

  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });
  const { selectedPeriod } = useAppSelector(({ period }) => period);
  const locale = storedLocale();

  const { isFetching, isError, data } = useGetBronzeEligibilityQuery({
    period: selectedPeriod,
    affAbo,
    locale,
    awardCode,
  });

  if (isShowSlider === false) {
    return <></>;
  }

  const getHistory = () => {
    const { months = [] } = data || {};
    return months.map((el) => ({ status: el.status, period: el.period })) as BonusHist[];
  };

  if (isFetching === true) {
    return (
      <div className="bi-eligibility-slider-loader">
        <div className="bi-eligibility-slider-loader__card">
          <LeafRectangleLoader isLoading={isFetching} numRows={1} width="95%" height="4rem" />
          <LeafRectangleLoader isLoading={isFetching} numRows={1} width="50%" height="2rem" />
          <LeafRectangleLoader isLoading={isFetching} numRows={1} width="50%" height="2rem" />
          <LeafRectangleLoader isLoading={isFetching} numRows={1} width="95%" height="30.5rem" />
        </div>
        <LeafRectangleLoader isLoading={isFetching} numRows={1} width="95%" height="2rem" />
        <div className="bi-eligibility-slider-loader__card">
          <LeafRectangleLoader isLoading={isFetching} numRows={1} width="95%" height="8rem" />
        </div>
      </div>
    );
  }

  return (
    <div className="bi-eligibility-slider-body">
      <div className="bi-eligibility-slider-body__eligibility-info">
        <div className="bi-eligibility-slider-body__eligibility-info__header">
          <p>{`${t("eligibleDescription", defaultContent["eligibleDescription"], {
            months: data?.programDuration,
          })}`}</p>
          <div className="bi-eligibility-slider-body__eligibility-info__dates">
            <span>
              <strong>{`${t("startDateLabel", defaultContent["startDateLabel"])}`}:</strong>{" "}
              {data && getBronzeDisplayDate(data.startPeriod)}
            </span>
            <span>
              <strong>{`${t("endDateLabel", defaultContent["endDateLabel"])}`}:</strong>{" "}
              {data && getBronzeDisplayDate(data.endPeriod)}
            </span>
          </div>
          {data?.notStarted === true && <div>{`${t("notStartedMessage", defaultContent["notStartedMessage"])}`}</div>}
        </div>
        {data?.notStarted === false && (
          <div className="bi-eligibility-slider-body__eligibility-info__badges_container">
            <LeafBadgeGrid history={getHistory()} />
          </div>
        )}
      </div>
      <div className="bi-eligibility-slider-body__eligibility-program-detail">
        <h2 className="bi-eligibility-slider-body__eligibility-program-detail__title">{`${t(
          "footerTitle",
          defaultContent["footerTitle"],
        )}`}</h2>
        <div className="bi-eligibility-slider-body__eligibility-program-detail__body">
          <p>{`${t("footerDescription", defaultContent["footerDescription"])}`}</p>
        </div>
      </div>
    </div>
  );
};

export default BIEligibilitySliderBody;
